import { asyncTypeFailure, asyncTypeRequest, asyncTypeSuccess } from '../types';
import userTypes, { UPDATE_PROVIDER } from '../types/user';

import get from 'lodash/get';

const initialState = {
  isAuthLoading: false,
  error: {
    login: null,
    myProfile: null,
    myMetadata: null
  },
  successState: {},
  token: '',
  myProfile: {
    isLoading: true,
    data: {},
    isUpdateSuccess: null,
    isLoadingUpdate: false
  },
  myBusiness: [],
  customer: {
    list: [],
    isLoadingList: false,
    created: null,
    isLoadingCreate: false
  },
  provider: {
    list: [],
    isLoadingList: false
  },
  category: {
    list: [],
    isLoadingList: false,
    created: null,
    isLoadingCreate: false
  },
  case: {
    created: null,
    isLoadingCreate: false
  },
  myMetadata: {
    isLoading: true,
    data: {}
  },
  suggestBusiness: []
};

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case userTypes.USER_LOGOUT: {
      return {
        ...initialState
      };
    }
    case asyncTypeRequest(userTypes.UPDATE_MY_PROFILE): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          error: null,
          isUpdateSuccess: null,
          isLoadingUpdate: true
        }
      };
    }
    case asyncTypeSuccess(userTypes.UPDATE_MY_PROFILE): {
      return {
        ...state,
        myProfile: {
          data: action.response.data,
          error: null,
          isUpdateSuccess: true,
          isLoadingUpdate: false
        }
      };
    }
    case asyncTypeFailure(userTypes.UPDATE_MY_PROFILE): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isUpdateSuccess: false,
          isLoadingUpdate: false
        }
      };
    }
    case asyncTypeRequest(userTypes.GET_MY_PROFILE): {
      return {
        ...state,
        myProfile: { ...initialState.myProfile, isLoading: true },
        isAuthLoading: true
      };
    }
    case asyncTypeSuccess(userTypes.GET_MY_PROFILE): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          data: action?.response?.data,
          isLoading: false,
          error: null
        },
        isAuthLoading: false
      };
    }
    case asyncTypeFailure(userTypes.GET_MY_PROFILE): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isLoading: false,
          isUpdateSuccess: false
        },
        isAuthLoading: false
      };
    }
    case asyncTypeRequest(userTypes.UPDATE_PASSWORD): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          error: null,
          isUpdateSuccess: null
        }
      };
    }
    case asyncTypeSuccess(userTypes.UPDATE_PASSWORD): {
      return {
        ...state,
        myProfile: {
          data: action.response.data,
          error: null,
          isUpdateSuccess: true
        }
      };
    }
    case asyncTypeFailure(userTypes.UPDATE_PASSWORD): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isLoading: false,
          isUpdateSuccess: false
        }
      };
    }
    case asyncTypeRequest(userTypes.UPDATE_AVATAR): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          error: null,
          isUpdateSuccess: null
        }
      };
    }
    case asyncTypeSuccess(userTypes.UPDATE_AVATAR): {
      return {
        ...state,
        myProfile: {
          data: action.response.data,
          error: null,
          isUpdateSuccess: true
        }
      };
    }
    case asyncTypeFailure(userTypes.UPDATE_AVATAR): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isLoading: false,
          isUpdateSuccess: false
        }
      };
    }
    case asyncTypeRequest(userTypes.ENABLE_MFA): {
      return {
        ...state,
        myProfile: {
          ...state.myProfile,
          error: null,
          isUpdateSuccess: null
        }
      };
    }
    case asyncTypeSuccess(userTypes.ENABLE_MFA): {
      return {
        ...state,
        myProfile: {
          data: action.response.data,
          error: null,
          isUpdateSuccess: true
        }
      };
    }
    case asyncTypeFailure(userTypes.ENABLE_MFA): {
      return {
        ...state,
        error: {
          ...state.error,
          myProfile: action?.error?.message
        },
        myProfile: {
          ...state.myProfile,
          isLoading: false,
          isUpdateSuccess: false
        }
      };
    }

    case userTypes.RESET_ERROR: {
      return {
        ...state,
        error: {},
        myProfile: {
          ...state.myProfile,
          isUpdateSuccess: null
        }
      };
    }
    case asyncTypeFailure(userTypes.USER_LOGIN): {
      return {
        ...state,
        error: {
          login: get(action, 'error.message')
        },
        isAuthLoading: false,
        isLoginSuccess: false,
        successState: {}
      };
    }
    case asyncTypeRequest(userTypes.USER_LOGIN): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        isLoginSuccess: false,
        successState: {}
      };
    }
    case asyncTypeSuccess(userTypes.USER_LOGIN): {
      if (get(action, 'response.data.accessToken')) {
        localStorage.setItem('token', get(action, 'response.data.accessToken'));
      }
      return {
        ...state,
        error: {},
        isAuthLoading: false,
        isLoginSuccess: true,
        token: get(action, 'response.data.accessToken'),
        successState: {
          isReSendPasscodeSuccess: get(action, 'payload.action') === 'resend'
        }
      };
    }
    case asyncTypeFailure(userTypes.USER_VERIFY_TWO_FACTOR):
    case asyncTypeFailure(userTypes.USER_VERIFY_LOGIN_OTP): {
      return {
        ...state,
        error: {
          login: get(action, 'error.message')
        },
        isAuthLoading: false
      };
    }
    case asyncTypeRequest(userTypes.USER_VERIFY_TWO_FACTOR):
    case asyncTypeRequest(userTypes.USER_VERIFY_LOGIN_OTP): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        successState: {}
      };
    }
    case asyncTypeSuccess(userTypes.USER_VERIFY_TWO_FACTOR):
    case asyncTypeSuccess(userTypes.USER_VERIFY_LOGIN_OTP): {
      return {
        ...state,
        error: {},
        isAuthLoading: false,
        token: get(action, 'response.data.accessToken')
      };
    }

    case asyncTypeFailure(userTypes.USER_SEND_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {
          forgotPassword: get(action, 'error.message')
        },
        isAuthLoading: false,
        successState: {}
      };
    }

    case asyncTypeRequest(userTypes.USER_SEND_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        successState: {}
      };
    }

    case asyncTypeSuccess(userTypes.USER_SEND_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: false,
        successState: {
          isReSendForgotPassSuccess: get(action, 'payload.action') === 'resend'
        }
      };
    }

    case asyncTypeFailure(userTypes.USER_RESET_PASSWORD): {
      return {
        ...state,
        error: {
          forgotPassword: get(action, 'error.message')
        },
        isAuthLoading: false,
        successState: {}
      };
    }

    case asyncTypeRequest(userTypes.USER_RESET_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        successState: {}
      };
    }

    case asyncTypeSuccess(userTypes.USER_RESET_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: false,
        successState: {
          isResetPassSuccess: get(action, 'response.message')
        }
      };
    }
    case asyncTypeFailure(userTypes.USER_VERIFY_OTP_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {
          forgotPassword: get(action, 'error.message')
        },
        isAuthLoading: false,
        successState: {}
      };
    }

    case asyncTypeRequest(userTypes.USER_VERIFY_OTP_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: true,
        successState: {}
      };
    }

    case asyncTypeSuccess(userTypes.USER_VERIFY_OTP_FORGOT_PASSWORD): {
      return {
        ...state,
        error: {},
        isAuthLoading: false
      };
    }

    case asyncTypeRequest(userTypes.RESET_USER): {
      return {
        ...state,
        error: {
          login: null,
          myProfile: null
        },
        successState: {},
        isUpdateSuccess: false,
        isLoginSuccess: false
      };
    }
    case asyncTypeRequest(userTypes.GET_MY_BUSINESS): {
      return {
        ...state,
        isAuthLoading: true
      };
    }
    case asyncTypeSuccess(userTypes.GET_MY_BUSINESS): {
      return {
        ...state,
        myBusiness: get(action, 'response.data'),
        isAuthLoading: false
      };
    }
    case asyncTypeFailure(userTypes.GET_MY_BUSINESS): {
      return {
        myBusiness: [],
        isAuthLoading: false
      };
    }
    case asyncTypeRequest(userTypes.GET_MY_BUSINESS_TOKEN): {
      return {
        ...state,
        isAuthLoading: true
      };
    }
    case asyncTypeSuccess(userTypes.GET_MY_BUSINESS_TOKEN): {
      return {
        ...state,
        isAuthLoading: false,
        token: get(action, 'response.data.accessToken')
      };
    }
    case asyncTypeFailure(userTypes.GET_MY_BUSINESS_TOKEN): {
      return {
        ...state,
        isAuthLoading: false
      };
    }
    case asyncTypeSuccess(userTypes.USER_LOGOUT):
    case asyncTypeRequest(userTypes.USER_LOGOUT): {
      return {
        ...initialState
      };
    }

    // get providers
    case asyncTypeRequest(userTypes.GET_PROVIDERS): {
      return {
        ...state,
        provider: {
          ...state.provider,
          isLoadingList: true
        }
      };
    }
    case asyncTypeSuccess(userTypes.GET_PROVIDERS): {
      const { isLoadmore } = action?.payload;
      return {
        ...state,
        provider: {
          ...state.provider,
          isLoadingList: false,
          list: isLoadmore
            ? [...state.provider.list, ...action?.response?.data]
            : action?.response?.data
        }
      };
    }
    case asyncTypeFailure(userTypes.GET_PROVIDERS): {
      return {
        categories: {
          ...state.categories,
          isLoadingList: false
        }
      };
    }
    case UPDATE_PROVIDER: {
      return {
        ...state,
        provider: {
          ...state.provider,
          list: [...state.provider.list, action?.data]
        }
      };
    }
    // get categories
    case asyncTypeRequest(userTypes.GET_CATEGORIES): {
      return {
        ...state,
        category: {
          ...state.category,
          isLoadingList: true
        }
      };
    }
    case asyncTypeSuccess(userTypes.GET_CATEGORIES): {
      const { isLoadmore } = action?.payload;
      return {
        ...state,
        category: {
          ...state.category,
          isLoadingList: false,
          list: isLoadmore
            ? [...state.category.list, ...action?.response?.data]
            : action?.response?.data
        }
      };
    }
    case asyncTypeFailure(userTypes.GET_CATEGORIES): {
      return {
        category: {
          ...state.category,
          isLoadingList: false
        }
      };
    }
    // create category
    case asyncTypeRequest(userTypes.CREATE_CATEGORY): {
      return {
        ...state,
        category: {
          ...state.category,
          isLoadingCreate: true
        }
      };
    }
    case asyncTypeSuccess(userTypes.CREATE_CATEGORY): {
      return {
        ...state,
        category: {
          ...state.category,
          isLoadingCreate: false,
          created: action?.response?.data || {}
        }
      };
    }
    case asyncTypeFailure(userTypes.CREATE_CATEGORY): {
      return {
        category: {
          ...state.category,
          isLoadingCreate: false
        }
      };
    }
    // create case
    case asyncTypeRequest(userTypes.CREATE_CASE): {
      return {
        ...state,
        case: {
          ...state.case,
          isLoadingCreate: true
        }
      };
    }
    case asyncTypeSuccess(userTypes.CREATE_CASE): {
      return {
        ...state,
        case: {
          ...state.case,
          isLoadingCreate: false,
          created: action?.response?.data || {}
        }
      };
    }
    case asyncTypeFailure(userTypes.CREATE_CASE): {
      return {
        case: {
          ...state.case,
          isLoadingCreate: false
        }
      };
    }

    case asyncTypeRequest(userTypes.GET_MY_METADATA): {
      return {
        ...state,
        myMetadata: {
          ...state.myMetadata,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(userTypes.GET_MY_METADATA): {
      return {
        ...state,
        myMetadata: {
          ...state.myMetadata,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          myMetadata: null
        }
      };
    }

    case asyncTypeFailure(userTypes.GET_MY_METADATA): {
      return {
        ...state,
        myMetadata: {
          ...state.myMetadata,
          isLoading: false
        },
        error: {
          ...state.error,
          myMetadata: action?.error?.message
        }
      };
    }
    case asyncTypeRequest(userTypes.GET_SUGGEST_BUSINESS): {
      return {
        ...state,
        suggestBusiness: {
          ...state.suggestBusiness,
          isLoading: true
        }
      };
    }
    case asyncTypeSuccess(userTypes.GET_SUGGEST_BUSINESS): {
      return {
        ...state,
        suggestBusiness: {
          ...state.suggestBusiness,
          data: action?.response?.data,
          isLoading: false
        },
        error: {
          ...state.error,
          suggestBusiness: null
        }
      };
    }

    case asyncTypeFailure(userTypes.GET_SUGGEST_BUSINESS): {
      return {
        ...state,
        suggestBusiness: {
          ...state.suggestBusiness,
          isLoading: false
        }
      };
    }

    default:
      return state;
  }
};

export default userReducers;
