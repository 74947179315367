import appTypes from '../types/app';
import userTypes from '../types/user';
import { asyncTypeRequest, asyncTypeSuccess, asyncTypeFailure } from '../types';

const initialState = {
  isLoadingFullscreen: false,
  modal: {
    modalActive: '',
    data: {}
  },
  settings: {}
};

const appReducers = (state = initialState, action) => {
  switch (action.type) {
    case appTypes.SET_LOADING_FULLSCREEN: {
      return {
        ...state,
        isLoadingFullscreen: action.payload
      };
    }
    case appTypes.SET_MODAL_ACTIVE: {
      const { modalName, data, isNested } = action.payload;
      if(isNested) {
        return {
          ...state,
          modal: {
            ...state?.modal,
            nestedModalActive: modalName,
            nestedModalData: { ...data }
          }
        };
      }
      return {
        ...state,
        modal: { modalActive: modalName, data: { ...data } }
      };
    }
    case appTypes.SET_USER_METADATA: {
      return {
        ...state,
        metadata: { ...state.metadata, ...action.payload }
      };
    }
    case asyncTypeRequest(userTypes.USER_LOGOUT): {
      return {
        ...initialState
      };
    }
    case asyncTypeSuccess(appTypes.GET_SETTINGS): {
      const resData = action?.response?.data || {};
      const submitRequestTypes = resData?.submit_request_types || {};
      return {
        ...state,
        settings: resData
          ? ({
            submitRequestTypes,
            submitRequestTypeOpts: Object.keys(submitRequestTypes)
              .map(key => ({
                ...submitRequestTypes[key],
                isDefault: key === 'lop',
                hasDeal: key === 'lob',
                id: key,
                label: submitRequestTypes[key]?.title
              })),
            requestTypes: resData?.request_types || {},
            defaultCaseThumbnails: resData?.default_case_thumbnails || [],
            defaultAvatars: resData?.default_avatars || []
          })
          : initialState?.settings
      };
    }
    case asyncTypeFailure(appTypes.GET_SETTINGS): {
      return {
        ...state,
        settings: initialState?.settings
      };
    }
    default: {
      return state;
    }
  }
};

export default appReducers;
