import { createTypes } from 'helpers/createTypes';

export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';

export default createTypes(
  'USER_LOGIN',
  'UPDATE_MY_PROFILE',
  'GET_MY_PROFILE',
  'UPDATE_PASSWORD',
  'ENABLE_MFA',
  'RESET_ERROR',
  'USER_VERIFY_LOGIN_OTP',
  'RESET_USER',
  'USER_VERIFY_TWO_FACTOR',
  'USER_SEND_FORGOT_PASSWORD',
  'USER_RESET_PASSWORD',
  'USER_LOGOUT',
  'USER_VERIFY_OTP_FORGOT_PASSWORD',
  'RESPONSE_BUSINESS_INVITATION',
  'GET_MY_BUSINESS',
  'GET_MY_BUSINESS_TOKEN',
  'GET_PROVIDERS',
  'GET_CATEGORIES',
  'CREATE_CATEGORY',
  'CREATE_CASE',
  'GET_MY_METADATA',
  'UPDATE_AVATAR',
  'GET_SUGGEST_BUSINESS'
);
