import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // de

import appReducer from 'portals/personal/store/reducers/app';
import userReducer from 'portals/personal/store/reducers/user';
import requestReducer from 'portals/personal/store/reducers/request';

const persistConfig = {
  key: 'PT-user',
  storage,
  whitelist: ['token', 'myProfile']
};

const persistAppConfig = {
  key: 'PT-app',
  storage,
  blacklist: ['modal']
};


const rootReducer = combineReducers({
  app: persistReducer(persistAppConfig, appReducer),
  user: persistReducer(persistConfig, userReducer),
  requests: requestReducer
});

export default rootReducer;
