import i18n, { addResources } from 'configs/I18n';

const resources = {
  en: {},
  vi: {}
};

addResources(resources, 'personal');

export default i18n;
